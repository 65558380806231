import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from '../../services/i18n';
import TargetableLogo from '../../components/Images/TargetableLogo/TargetableLogo';
import SupportForm from '../../components/SupportForm/SupportForm';

const styles = {
  page: {
    backgroundColor: '#ffffff',
    color: '#1d173b',
    height: '100vh',
    padding: '21px',
    margin: '0 auto',
    width: '100%',
    maxWidth: '600px',
  },
  logo: {
    margin: '1rem auto 2rem',
    display: 'block',
  },
  headline: {
    marginBottom: '2rem',
  },
  body: {
    marginTop: '1rem',
  },
  hbstCtn: {
    marginTop: '40px',
  },
};

const GlobalError = ({ classes }) => (
  <div className={classes.page}>
    <TargetableLogo height={70} width={70} className={classes.logo} />
    <Typography className={classes.headline} variant="h2">
      {i18n.t('error_page_message_headline')}
    </Typography>
    <Typography className={classes.body} variant="body1">
      {i18n.t('error_page_message_body')}
    </Typography>
    <SupportForm />
  </div>
  );

GlobalError.propTypes = {
  classes: PropTypes.shape({
    body: PropTypes.string,
    headline: PropTypes.string,
    logo: PropTypes.string,
    page: PropTypes.string,
    hbstCtn: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(GlobalError);
