import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import api from '../../services/api';
import { showToaster } from '../../actions';
import env from '../../globals';
import { selectBusiness } from '../../selectors';
import { getToasterOptions } from '../../sagas/helpers';

const styles = {
  page: {
    backgroundColor: '#ffffff',
    color: '#1d173b',
    height: '100vh',
    padding: '21px',
    margin: '0 auto',
    width: '100%',
    maxWidth: '600px',
  },
  logo: {
    margin: '1rem auto 2rem',
    display: 'block',
  },
  headline: {
    marginBottom: '2rem',
  },
  form: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  submitButton: {
    width: '150px',
    marginTop: '1rem',
    backgroundColor: '#d9534f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c9302c',
    },
  },
};

const SupportForm = ({ classes }) => {
  const dispatch = useDispatch();
  const business = useSelector(selectBusiness);
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    comments: '',
  });
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!formData.comments.trim()) newErrors.comments = 'Comments are required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setSending(true);
    const customProperties = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email,
      subject: t('support_form_subject'),
      main_message: formData.comments.replace(/\n/g, '<br>'),
    };

    const data = {
      customProperties,
      emailId:
        env.SUPPORT_FORM_EMAIL_TEMPLATE,
      business_name: business?.name || 'Unknown Business',
      businessId: business?.id || 'Unknown Business ID',
      userId: `${formData.email}_support_request`,
      email: env.SUPPORT_FORM_EMAIL || formData.email,
    };

    try {
      dispatch(
        showToaster(
          getToasterOptions(
            'Sending your request...',
            'info',
            null,
            20000,
            true,
            null,
            'bottom',
            'right',
          ),
        ),
      );
      await api.sendTransactionalEmail(data);
      dispatch(
        showToaster(
          getToasterOptions(
            'Your request was sent successfully',
            'success',
            null,
            5000,
          ),
        ),
      );
      setFormData({
 firstName: '', lastName: '', email: '', comments: '',
});
      setSending(false);
      setErrors({});
    } catch (error) {
      setSending(false);
      dispatch(showToaster(getToasterOptions('error_whoops', 'error')));
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <TextField
          name="firstName"
          label="First name"
          variant="outlined"
          value={formData.firstName}
          onChange={handleChange}
          error={!!errors.firstName}
          helperText={errors.firstName}
          fullWidth
          required
        />
        <TextField
          name="lastName"
          label="Last name"
          variant="outlined"
          value={formData.lastName}
          onChange={handleChange}
          error={!!errors.lastName}
          helperText={errors.lastName}
          fullWidth
          required
        />
      </div>
      <TextField
        name="email"
        variant="outlined"
        label="Email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        error={!!errors.email}
        helperText={errors.email}
        fullWidth
        required
      />
      <TextField
        name="comments"
        label="Comment(s)"
        variant="outlined"
        multiline
        rows={4}
        value={formData.comments}
        onChange={handleChange}
        error={!!errors.comments}
        helperText={errors.comments}
        fullWidth
        required
      />
      <Button
        type="submit"
        variant="contained"
        className={classes.submitButton}
        disabled={sending}
      >
        Submit
      </Button>
    </form>
  );
};

SupportForm.propTypes = {
  classes: PropTypes.shape({
    form: PropTypes.string,
    submitButton: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(SupportForm);
